import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';

export function Image({ filename, alt, className }) {
  const data = useStaticQuery(graphql`query ImageFluid {
  images: allFile {
    edges {
      node {
        relativePath
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100,
            layout: CONSTRAINED,
            placeholder: BLURRED
          )
        }
      }
    }
  }
}
`);

  // get file
  const image = data.images.edges.find(img => {
    return img.node.relativePath.includes(filename);
  });

  if (!image) {
    return null;
  }

  return (
    <GatsbyImage
      alt={alt}
      className={className}
      image={image.node.childImageSharp.gatsbyImageData}
    />
  );
};