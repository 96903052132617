import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'gatsby-theme-material-ui';

import { Stack } from '@mui/material';

import { Image } from '../hooks/image';
import { Reveal } from '../hooks/reveal';
import { setIs404 } from '../store/appSlice';
import { Title } from '../components/utils/utils';
import { useStyles } from './notFoundPageView.styles';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default function NotFoundPageView() {
  const dispatch = useDispatch();
  const { author } = useSiteMetadata();

  useEffect(() => {
    dispatch(setIs404(true));
  }, []);

  const remove404State = () => dispatch(setIs404(false));

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ ...useStyles.root }}
    >
      <Reveal sx={{ ...useStyles.logoWrap }}>
        <Image
          filename="logo_404.png"
          alt={`Logo 404 error of ${author}`}
        />
      </Reveal>
      <Reveal delay="0.4" sx={{ ...useStyles.textWrap }}>
        <Title component="h1" removeMb={true}>
          Nous sommes désolés, la page recherchée n'existe pas ou a été déplacée.
        </Title>
      </Reveal>
      <Reveal
        delay="0.4"
        sx={{ ...useStyles.buttonWrap }}
      >
        <Button
          to="/"
          size="large"
          variant="contained"
          onClick={remove404State}
          sx={{ ...useStyles.button }}
        >
          Retour à l'accueil
        </Button>
      </Reveal>
    </Stack>
  );
};