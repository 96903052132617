import { customization } from '../layout/Theme';

export const useStyles = {
  root: {
    p: 2,
    m: 'auto',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    maxWidth: customization.breakpoints.md,
  },

  logoWrap: {
    maxWidth: 200,
  },

  textWrap: {
    my: 8,
    textAlign: 'center',
  },

  buttonWrap: {
    width: '100%',
    textAlign: 'center',
  },

  button: {
    fontSize: theme => theme.typography.pxToRem(16),
  },
};