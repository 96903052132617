import React from 'react';

import Seo from '../components/seo/Seo';
import NotFoundPageView from '../views/NotFoundPageView';

export default function NotFoundPage() {
  return (
    <>
      <Seo
        currentTitle="Erreur 404"
        currentDescription="Erreur 404, la page recherchée n'existe pas ou a été déplacée."
      />

      <NotFoundPageView />
    </>
  );
};
